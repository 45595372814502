import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import store from "@/store/";
import { USER_LEVEL } from "@/enum/enums";
// Containers
import TheContainer from "@/containers/the-container.vue";
import DefaultContainer from "@/containers/default-container.vue";

import error from "@/pages/error/index.vue";
import error404 from "@/pages/error/404.vue";
import error406 from "@/pages/error/406.vue";
import invalidGroup from "@/pages/error/invalid-group.vue";
import projectList from "@/pages/project/list/index.vue";

const { SUPER_ADMIN, GENERAL_ADMIN, PMI_USER, USER } = USER_LEVEL;

Vue.use(VueRouter);
const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "default",
    redirect: "/login",
    component: DefaultContainer,
    children: [
      {
        path: "login",
        name: "login",
        component: () => import("@/pages/users/login.vue"),
        meta: {
          unauthorized: true,
        },
      },
      {
        path: "user/create/:userGroupId",
        name: "userCreate",
        component: () => import("@/pages/users/create.vue"),
        meta: {
          unauthorized: true,
        },
      },
    ],
  },
  {
    path: "/overview",
    // name: 'overview',
    component: TheContainer,
    children: [
      {
        path: "",
        name: "overview",
        component: () => import("@/pages/overview/index.vue"),
        meta: {
          auth: [SUPER_ADMIN, GENERAL_ADMIN, PMI_USER, USER],
          menu: { root: "overview", sub: "" },
        },
      },
    ],
  },
  {
    path: "/project",
    component: TheContainer,
    children: [
      {
        path: "",
        name: "projectList",
        component: projectList,
        meta: {
          auth: [SUPER_ADMIN, GENERAL_ADMIN, PMI_USER, USER],
          menu: { root: "project", sub: "project-list" },
        },
      },
      {
        path: "registration/:surveyId?",
        name: "projectRegistration",
        component: () => import("@/pages/project/registration/index.vue"),
        meta: {
          auth: [SUPER_ADMIN, GENERAL_ADMIN, PMI_USER, USER],
          menu: { root: "project", sub: "project-registration" },
        },
      },
      {
        path: "created/home/:surveyId",
        component: () => import("@/pages/project/created/home/home.vue"),
        children: [
          {
            path: "",
            name: "projectCreatedHome",
            component: projectList,
            meta: {
              auth: [SUPER_ADMIN, GENERAL_ADMIN, PMI_USER, USER],
              menu: { root: "project", sub: "project-list" },
            },
          },
        ],
      },
      {
        path: "created/info/:surveyId",
        component: () => import("@/pages/project/created/info.vue"),
        children: [
          {
            path: "",
            name: "projectCreatedInfo",
            component: projectList,
            meta: {
              auth: [SUPER_ADMIN, GENERAL_ADMIN, PMI_USER, USER],
              menu: { root: "project", sub: "project-list" },
            },
          },
        ],
      },
      {
        path: "created/urls/:surveyId",
        component: () => import("@/pages/project/created/urls.vue"),
        children: [
          {
            path: "",
            name: "projectCreatedUrls",
            component: projectList,
            meta: {
              auth: [SUPER_ADMIN, GENERAL_ADMIN, PMI_USER, USER],
              menu: { root: "project", sub: "project-list" },
            },
          },
        ],
      },
      {
        path: "created/quota/:surveyId",
        component: () => import("@/pages/project/created/quota/quota.vue"),
        children: [
          {
            path: "",
            name: "projectCreatedQuota",
            component: projectList,
            meta: {
              auth: [SUPER_ADMIN, GENERAL_ADMIN, PMI_USER, USER],
              menu: { root: "project", sub: "project-list" },
            },
          },
        ],
      },
      {
        path: "created/sampling/:surveyId",
        component: () =>
          import("@/pages/project/created/sampling/sampling.vue"),
        children: [
          {
            path: "",
            name: "projectCreatedSampling",
            component: projectList,
            meta: {
              auth: [SUPER_ADMIN, GENERAL_ADMIN, PMI_USER, USER],
              menu: { root: "project", sub: "project-list" },
            },
          },
        ],
      },
      {
        path: "created/respondent/:surveyId",
        component: () =>
          import("@/pages/project/created/respondent/respondent.vue"),
        children: [
          {
            path: "",
            name: "projectCreatedRespondent",
            component: projectList,
            meta: {
              auth: [SUPER_ADMIN, GENERAL_ADMIN, PMI_USER, USER],
              menu: { root: "project", sub: "project-list" },
            },
          },
        ],
      },
      {
        path: "summary",
        name: "projectSummary",
        component: () => import("@/pages/project/summary/summary.vue"),
        meta: {
          auth: [SUPER_ADMIN, GENERAL_ADMIN, PMI_USER, USER],
          menu: { root: "project", sub: "project-summary" },
        },
      },
      {
        path: "billing",
        name: "project-billing",
        component: () => import("@/pages/project/billing/billing.vue"),
        children: [
          {
            path: "invoice",
            name: "billing-specific",
            component: () =>
              import("@/pages/project/billing/billing-specific.vue"),
            meta: {
              auth: [SUPER_ADMIN, GENERAL_ADMIN, PMI_USER, USER],
              menu: { root: "project", sub: "project-billing" },
            },
          },
        ],
        meta: {
          auth: [USER],
          menu: { root: "project", sub: "project-billing" },
        },
      },
      {
        path: "billing/invoice-confirm",
        name: "invoice",
        component: () => import("@/pages/project/billing/invoice-confirm.vue"),
        meta: {
          auth: [SUPER_ADMIN, GENERAL_ADMIN, PMI_USER, USER],
          menu: { root: "project", sub: "project-billing" },
        },
      },
      {
        path: "confirm-costs",
        name: "confirm-costs",
        component: () => import("@/pages/project/confirm-costs/index.vue"),
        children: [
          {
            path: "",
            name: "confirm-costs-month",
            component: () => import("@/pages/project/confirm-costs/month.vue"),
            meta: {
              auth: [SUPER_ADMIN, GENERAL_ADMIN, PMI_USER, USER],
              menu: { root: "project", sub: "confirm-costs" },
            },
          },
          {
            path: "month",
            name: "confirm-costs-month",
            component: () => import("@/pages/project/confirm-costs/month.vue"),
            meta: {
              auth: [SUPER_ADMIN, GENERAL_ADMIN, PMI_USER, USER],
              menu: { root: "project", sub: "confirm-costs" },
            },
          },
          {
            path: "project",
            name: "confirm-costs-list",
            component: () =>
              import("@/pages/project/confirm-costs/project.vue"),
            meta: {
              auth: [SUPER_ADMIN, GENERAL_ADMIN, PMI_USER, USER],
              menu: { root: "project", sub: "confirm-costs" },
            },
          },
        ],
        meta: {
          auth: [SUPER_ADMIN, GENERAL_ADMIN, PMI_USER, USER],
          menu: { root: "project", sub: "confirm-costs" },
        },
      },
    ],
  },

  {
    path: "/my-page",
    name: "myPage",
    redirect: "/my-page/board",
    component: TheContainer,
    children: [
      {
        path: "board",
        name: "myPageBoard",
        component: () => import("@/pages/my-page/board/board.vue"),
        meta: {
          auth: [SUPER_ADMIN, GENERAL_ADMIN, PMI_USER, USER],
          menu: { root: "my-page", sub: "my-page-board" },
        },
      },
      {
        path: "board/:_id/write",
        component: () => import("@/pages/my-page/board/edit.vue"),
        meta: {
          auth: [SUPER_ADMIN, GENERAL_ADMIN, PMI_USER, USER],
          menu: { root: "my-page", sub: "my-page-board" },
        },
        children: [
          {
            path: "",
            name: "board-write",
            component: () => import("@/pages/my-page/board/board.vue"),
            meta: {
              auth: [SUPER_ADMIN, GENERAL_ADMIN, PMI_USER, USER],
              menu: { root: "my-page", sub: "my-page-board" },
            },
          },
        ],
      },
      {
        path: "board/:_id/edit",
        component: () => import("@/pages/my-page/board/edit.vue"),
        meta: {
          auth: [SUPER_ADMIN, GENERAL_ADMIN, PMI_USER, USER],
          menu: { root: "my-page", sub: "my-page-board" },
        },
        children: [
          {
            path: "",
            name: "board-edit",
            component: () => import("@/pages/my-page/board/board.vue"),
            meta: {
              auth: [SUPER_ADMIN, GENERAL_ADMIN, PMI_USER, USER],
              menu: { root: "my-page", sub: "my-page-board" },
            },
          },
        ],
      },
      {
        path: "board/:_id/reply",
        component: () => import("@/pages/my-page/board/reply.vue"),
        meta: {
          auth: [SUPER_ADMIN, GENERAL_ADMIN, PMI_USER, USER],
          menu: { root: "my-page", sub: "my-page-board" },
        },
        children: [
          {
            path: "",
            name: "board-reply",
            component: () => import("@/pages/my-page/board/board.vue"),
            meta: {
              auth: [SUPER_ADMIN, GENERAL_ADMIN, PMI_USER, USER],
              menu: { root: "my-page", sub: "my-page-board" },
            },
          },
        ],
      },
      {
        path: "board/:_id",
        component: () => import("@/pages/my-page/board/read.vue"),
        meta: {
          auth: [SUPER_ADMIN, GENERAL_ADMIN, PMI_USER, USER],
          menu: { root: "my-page", sub: "my-page-board" },
        },
        children: [
          {
            path: "",
            name: "board-read",
            component: () => import("@/pages/my-page/board/board.vue"),
            meta: {
              auth: [SUPER_ADMIN, GENERAL_ADMIN, PMI_USER, USER],
              menu: { root: "my-page", sub: "my-page-board" },
            },
          },
        ],
      },
      {
        path: "profile",
        component: () => import("@/pages/my-page/profile/index.vue"),
        children: [
          {
            path: "",
            name: "profile",
            component: () => import("@/pages/my-page/profile/profile.vue"),
            meta: {
              auth: [SUPER_ADMIN, GENERAL_ADMIN, PMI_USER, USER],
              menu: { root: "my-page", sub: "my-page-profile" },
            },
          },
          {
            path: "password-confirm",
            name: "password-confirm",
            component: () =>
              import("@/pages/my-page/profile/password-confirm.vue"),
            meta: {
              auth: [SUPER_ADMIN, GENERAL_ADMIN, PMI_USER, USER],
              menu: { root: "my-page", sub: "my-page-profile" },
            },
          },
          {
            path: "password-change",
            name: "password-change",
            component: () =>
              import("@/pages/my-page/profile/password-change.vue"),
            meta: {
              auth: [SUPER_ADMIN, GENERAL_ADMIN, PMI_USER, USER],
              menu: { root: "my-page", sub: "my-page-profile" },
            },
          },
          {
            path: "edit",
            name: "profile-edit",
            component: () => import("@/pages/my-page/profile/profile-edit.vue"),
            meta: {
              auth: [SUPER_ADMIN, GENERAL_ADMIN, PMI_USER, USER],
              menu: { root: "my-page", sub: "my-page-profile" },
            },
          },
        ],
      },
    ],
  },
  {
    path: "/admin",
    name: "admin",
    redirect: "/admin/summary",
    component: TheContainer,
    children: [
      {
        path: "summary",
        name: "adminSummary",
        component: () => import("@/pages/project/summary/summary.vue"),
        meta: {
          auth: [SUPER_ADMIN, GENERAL_ADMIN],
          menu: { root: "admin", sub: "admin-summary" },
        },
      },
      {
        path: "buyer",
        name: "adminBuyer",
        component: () => import("@/pages/admin/buyer.vue"),
        meta: {
          auth: [SUPER_ADMIN, GENERAL_ADMIN],
          menu: { root: "admin", sub: "admin-buyer" },
        },
      },
      {
        path: "supplier",
        name: "adminSupplier",
        component: () => import("@/pages/admin/supplier.vue"),
        meta: {
          auth: [SUPER_ADMIN, GENERAL_ADMIN],
          menu: { root: "admin", sub: "admin-supplier" },
        },
      },
      {
        path: "config",
        name: "adminConfig",
        component: () => import("@/pages/admin/config.vue"),
        meta: {
          auth: [SUPER_ADMIN, GENERAL_ADMIN],
          menu: { root: "admin", sub: "admin-config" },
        },
      },
      /*{
        path: 'reward-batch',
        name: 'adminRewardBatch',
        component: () => import('@/pages/admin/reward-batch.vue'),
        meta: {
          auth: [SUPER_ADMIN, GENERAL_ADMIN]
        },
      },
      {
        path: 'join-history',
        name: 'adminJoinHistory',
        component: () => import('@/pages/admin/join-history.vue'),
        meta: {
          auth: [SUPER_ADMIN, GENERAL_ADMIN]
        },
      },
      {
        path: 'intro-html-manage',
        name: 'adminIntroHtmlManage',
        component: () => import('@/pages/admin/intro-html-manage.vue'),
        meta: {
          auth: [SUPER_ADMIN, GENERAL_ADMIN]
        },
      },*/
      {
        path: "board",
        name: "adminBoard",
        redirect: "/my-page/board",
        // component: () => import('@/pages/my-page/board/board.vue'),
        // meta: {
        //   auth: [SUPER_ADMIN, GENERAL_ADMIN],
        //   menu: { root: 'admin', sub: 'admin-board' },
        // },
      },
      {
        path: "send-notification",
        name: "adminSend-notification",
        component: () => import("@/pages/admin/send-notification.vue"),
        meta: {
          auth: [SUPER_ADMIN, GENERAL_ADMIN],
          menu: { root: "admin", sub: "admin-send-notification" },
        },
      },
    ],
  },
  {
    path: "/test",
    component: () => import("@/pages/test/test.vue"),

    children: [
      {
        path: "",
        component: () => import("@/pages/overview/index.vue"),
        name: "test",
        meta: {
          unauthorized: true,
          auth: ["user", "pmi-user", "general-admin", "super-admin"],
          menu: { root: "overview", sub: "" },
        },
      },
    ],
  },
  {
    path: "/error",
    name: "error",
    meta: {
      unauthorized: true,
    },
    component: error,
    children: [
      {
        path: "404",
        name: "404",
        component: error404,
        meta: {
          unauthorized: true,
        },
      },
      {
        path: "406",
        name: "406",
        component: error406,
        meta: {
          unauthorized: true,
        },
      },
      {
        path: "invalid-group",
        name: "invalidGroup",
        component: invalidGroup,
        meta: {
          unauthorized: true,
        },
      },
    ],
  },
  // {
  //   path: '*',
  //   redirect: '/error/404',
  //   meta: {
  //     unauthorized: true,
  //   }
  // }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.NODE_ENV === "development" ? "/" : "/",
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach(async (to, from, next) => {
  try {
    const { meta, name } = to;
    const { unauthorized, auth } = meta || { unauthorized: true };
    console.log(name);
    if (name === "login") {
      const verified = await store.dispatch("verify");
      console.log(verified);
      if (verified) {
        next("/overview");
      }
      return next();
    }
    // if (!unauthorized) {
    //   const verified = await store.dispatch("verify");
    //   if (!verified) next("/");
    //   if (!auth.includes(store.getters.userLevel)) {
    //     next("/login");
    //   }
    // }
    next();
  } catch (e) {
    next("/");
  }
});

export default router;
