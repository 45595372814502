
import { Component, Vue } from 'vue-property-decorator'
import moment from 'moment'

interface Response {
  time: string;
}
@Component
export default class TheTimer extends Vue {
  private time = {
    hour: '',
    minutes: '',
    seconds: '',
    month: '',
    day: '',
    week: '',
    pm: '',
  }
  private currentTime: Date = new Date();
  private interval: ReturnType<typeof setInterval> | null = null;

  created() {
    (async () => {
      await this.load();
      this.renderTime();
      this.timer();
    })();

    document.addEventListener("visibilitychange", async () => {
      console.log('visibilitychange');
      await this.load();
    });
  }

  beforeDestroy(): void {
    if (this.interval) clearInterval(this.interval);
  }

  async load() {
    const { data } = await this.axios({
      url: '/notice/current-time'
    });

    const { time } = data as Response;
    this.currentTime = new Date(time);
  }
  timer() {
    this.interval = setInterval(this.renderTime, 1000);
  }
  renderTime() {
    this.currentTime = new Date(this.currentTime.getTime() + 1000);
    const t = moment(this.currentTime);
    t.locale(this.$i18n.locale);
    this.time = {
      hour: t.format('hh'),
      minutes: t.format('mm'),
      seconds: t.format('ss'),
      month: t.format('MM'),
      day: t.format('DD'),
      week: t.format('dddd'),
      pm: t.format('A'),
    }
  }
}
