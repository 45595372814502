import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import axios from './plugins/axios'
import VueAxios from 'vue-axios'
import { GlobalMixin } from '@/mixins/global-mixin';
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue/'
import { messages, defaultLocale } from "@/i18n";
import VueI18n from 'vue-i18n'
import LocaleChanger from '@/components/locale-changer.vue';
import SkeletonRows from '@/components/skeleton-rows.vue';
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import Notifications from 'vue-notification'
import PerfectScrollbar from 'vue2-perfect-scrollbar'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'
import VueIntersect from 'vue-intersect-directive'
import moment from 'moment';
import { Locales } from './i18n/locales';
library.add(fas)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('font-awesome-layers', FontAwesomeLayers)
Vue.component('font-awesome-layers-text', FontAwesomeLayersText)
moment.updateLocale((store.getters.locale || Locales.KO).substring(0, 2), {});
Vue.config.productionTip = false
Vue.use(VueAxios, axios);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(VueI18n);
Vue.component('LocaleChanger', LocaleChanger);
Vue.component('skeleton-rows', SkeletonRows);
Vue.use(Notifications);
Vue.use(PerfectScrollbar)
Vue.use(VueIntersect);

Vue.filter("numberFormat", (val: number) =>{
  return new Intl.NumberFormat().format(val)
})

// 다국어
const i18n = new VueI18n({
  messages,
  locale: defaultLocale,
  fallbackLocale: defaultLocale,
})

String.prototype.date = function() {
  return new Date(this as string).toLocaleString();
}
String.prototype.yyymmdd = function() {
  return new Date(this as string).toLocaleDateString();
}

Number.prototype.digit = function() {
  return new Intl.NumberFormat().format(this as number);
}


Vue.mixin(GlobalMixin);
const app = new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
export default app;
