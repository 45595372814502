
import { Vue, Component, Prop } from 'vue-property-decorator';
import { USER_LEVEL } from '@/enum/enums';

interface Menu {
  readonly icon: string,
  readonly text: string,
  readonly to: string,
  readonly name: string,
  readonly auth: USER_LEVEL[],
  readonly sub: Omit<Menu,'sub'>[],
}

@Component
export default class TheSideMenu extends Vue {
  @Prop() currentRoot!: string;
  @Prop() currentSub!: string;
  @Prop() id!: string;
  @Prop() menu!: Menu;


}
