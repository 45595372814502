
import { Vue, Component, PropSync } from 'vue-property-decorator';
import { PROJECT_SORT } from '@/enum/enums';

interface Managers {
  userGroup: string,
  userId: string,
  userName: string,
  _id: string,
}

@Component
export default class ListFilter extends Vue {
  @PropSync('filterType', { type: String }) filterTypeSync!: string;
  @PropSync('filterValue', { type: String }) filterValueSync!: string;
  @PropSync('sortOrder', { type: String }) sortOrderSync!: string;
  private startDate = '';
  private endDate = '';
  private managers: Managers[] = [];

  private created() {
    if (this.projectStartDate) this.startDate = this.projectStartDate;
    if (this.projectEndDate) this.endDate = this.projectEndDate;
  }

  async managerSearch() {
    try {
      const { result, data, message } = await this.axios.get('/user/search-manager', {
        params: {
          manager: this.filterValueSync,
        },
      });

      if (!result) throw message;

      this.managers = data.managers as Managers[];
    } catch (e) {
      this.validationMessages(e);
    }
  }

  private filterSubmit() {
    const params = {
      filterType: this.filterTypeSync,
      filterValue: this.filterValueSync,
      sortOrder: this.sortOrderSync,
      startDate: this.startDate,
      endDate: this.endDate,
    };
    this.$emit('filterSubmit', params);
  }

  get sort() {
    return PROJECT_SORT;
  }
}
