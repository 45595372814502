
import { Component, Prop, Vue } from 'vue-property-decorator';
import { SocketIO } from '@/plugins/socket';
import { Socket } from 'vue-socket.io-extended';
import { SOCKET_EVENTS } from '@/enum/enums';
import { SocketMessage } from '@/interface/interface';
@Component({})
export default class SocketComponent extends Vue {
  private connected = false;
  private readonly instance: SocketIO;
  constructor() {
    super();
    this.instance = SocketIO.getInstance();
    console.log('socket init');
  }

  get isConnected() {
    return this.connected;
  }

  @Socket(SOCKET_EVENTS.REC_MESSAGE)
  recMessage(message: SocketMessage) {
    const { toast } = message;
    console.log(message);
    const { title, message: text } = toast;
    this.$notify({
      group: 'message',
      title,
      text,
      type: 'success',
    });
  }
}
