import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import users from './modules/users';
import registration from './modules/registration';
import createPersistedState from 'vuex-persistedstate';
import Cookies from 'js-cookie';
import sockets from '@/store/modules/sockets';
import global from '@/store/modules/global';
import { PROJECT_STATUS } from '@/enum/enums';
Vue.use(Vuex)

export interface State {
  accessToken: null | string,
  locale: string,
  sidebarShow: string | boolean,
  sidebarMinimize: false | 'string',
  overviewMessage: boolean,
  projectStartDate: string,
  projectEndDate: string,
  projectStatus: PROJECT_STATUS,
  [keys: string]: any,
}

const state: State = {
  accessToken: null,
  locale: 'ko-KR',
  sidebarShow: 'responsive',
  sidebarMinimize: false,
  overviewMessage: true,
  projectStartDate: '',
  projectEndDate: '',
  projectStatus: PROJECT_STATUS.CREATED
}

const paths = [
  'accessToken',
  'locale',
  'sidebarShow',
  'sidebarMinimize',
  'overviewMessage',
  'projectStartDate',
  'projectEndDate',
  'projectStatus',
  'users.userId',
  'users.userGroup',
  'users.userName',
  'users.userLevel',
  'users.firstName',
  'users.lastName',
  'registration.step',
  'registration.surveyId',
  'registration.btnDisabled',
];

const storeOptions: StoreOptions<State> =  {
  plugins: [createPersistedState({ paths,
    storage: {
      getItem: key => Cookies.get(key),
      setItem: (key, value) => Cookies.set(key, value, { expires: 3, secure: true }),
      removeItem: key => Cookies.remove(key)
    } })],
  state,
  getters: {
    accessToken: ({ accessToken }) => accessToken,
    locale: ({ locale}) => locale || 'ko-KR',
    overviewMessage: ({ overviewMessage }) => overviewMessage,
    projectStartDate: ({ projectStartDate }) => projectStartDate,
    projectEndDate: ({ projectEndDate }) => projectEndDate,
    projectStatus: ({ projectStatus }) => projectStatus,
  },
  mutations: {
    setToken(state, {accessToken}) {
      state.accessToken = accessToken
    },
    setLocale(state, locale: string) {
      state.locale = locale;
    },
    setProjectStartDate(state, date) {
      state.projectStartDate = date;
    },
    setProjectEndDate(state, date) {
      state.projectEndDate = date;
    },
    setProjectStatus(state, status) {
      state.projectStatus = status;
    },
    toggleSidebarDesktop(state) {
      const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
      state.sidebarShow = sidebarOpened ? false : 'responsive'
    },
    toggleSidebarMobile(state) {
      const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
      state.sidebarShow = sidebarClosed ? true : 'responsive'
    },
    set(state, [variable, value]) {
      state[variable] = value;
    },
    setOverviewMessage(state, value) {
      state.overviewMessage = value;
    }
  },
  actions: {
    localeChange(context, locale: string) {
      context.commit('setLocale', locale);
    },
  },
  modules: {
    users, registration, sockets, global
  }
}
export default new Vuex.Store(storeOptions);
