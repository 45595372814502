
import { Component, Vue, Watch } from 'vue-property-decorator';
import { USER_LEVEL } from '../enum/enums';
import TheTimer from '@/containers/the-timer.vue';
import TheSideMenu from '@/containers/the-side-menu.vue';

interface Menu {
  icon: string,
  text: string,
  to: string,
  name: string | string[],
  auth: USER_LEVEL[],
  sub?: Menu[],
}


@Component({
  components: {
    TheSideMenu,
    TheTimer,
  }
})
export default class TheSidebar extends Vue{
  private windowWidth = window.innerWidth;
  private routers: { [key: string]: Menu } = {
    overview: {
      icon: 'tachometer-alt',
      text: 'Overview',
      to: '/overview',
      name: 'overview',
      auth: [USER_LEVEL.USER, USER_LEVEL.PMI_USER, USER_LEVEL.SUPER_ADMIN],
      sub: [],
    },
    project: {
      icon: 'tasks',
      text: 'Project & Management',
      to: '',
      name: 'project',
      auth: [USER_LEVEL.USER, USER_LEVEL.PMI_USER, USER_LEVEL.SUPER_ADMIN],
      sub: [
        {
          icon: 'list',
          text: 'List',
          to: '/project',
          name: 'project-list',

          auth: [USER_LEVEL.USER, USER_LEVEL.PMI_USER, USER_LEVEL.SUPER_ADMIN],
        },
        {
          icon: 'plus',
          text: 'Registration',
          to: '/project/registration',
          name: 'project-registration',
          auth: [USER_LEVEL.USER, USER_LEVEL.PMI_USER, USER_LEVEL.SUPER_ADMIN],
        },
        {
          icon: 'dollar-sign',
          text: 'Confirm Costs',
          to: '/project/confirm-costs',
          name: 'confirm-costs',
          auth: [USER_LEVEL.PMI_USER, USER_LEVEL.SUPER_ADMIN],
        },
        {
          icon: 'dollar-sign',
          text: 'Confirm Costs',
          to: '/project/confirm-costs',
          name: 'confirm-costs',
          auth: [USER_LEVEL.USER],
        },
        {
          icon: 'search-location',
          text: 'Summary',
          to: '/project/summary',
          name: 'project-summary',
          auth: [USER_LEVEL.USER, USER_LEVEL.PMI_USER],
        },
        {
          icon: 'dollar-sign',
          text: 'Billing',
          to: '/project/billing',
          name: 'project-billing',
          auth: [USER_LEVEL.PMI_USER],
        },
      ],
    },
    myPage: {
      icon: 'user',
      text: 'My Page',
      to: '',
      name: 'my-page',
      auth: [USER_LEVEL.USER, USER_LEVEL.PMI_USER],
      sub: [
        {
          icon: 'clipboard-list',
          text: 'Board',
          to: '/my-page',
          name: 'my-page-board',
          auth: [USER_LEVEL.USER, USER_LEVEL.PMI_USER],
        },
        {
          icon: 'id-card',
          text: 'Profile',
          to: '/my-page/profile',
          name: 'my-page-profile',
          auth: [USER_LEVEL.USER, USER_LEVEL.PMI_USER],
        },
      ],
    },
    admin: {
      icon: 'tools',
      text: 'Admin',
      to: '',
      name: ['admin','my-page'],
      auth: [USER_LEVEL.SUPER_ADMIN, USER_LEVEL.GENERAL_ADMIN],
      sub: [
        {
          icon: 'search-location',
          text: 'Summary',
          to: '/admin/summary',
          name: 'admin-summary',
          auth: [USER_LEVEL.SUPER_ADMIN, USER_LEVEL.GENERAL_ADMIN],
        },
        {
          icon: 'dollar-sign',
          text: 'Buyer',
          to: '/admin/buyer',
          name: 'admin-buyer',
          auth: [USER_LEVEL.SUPER_ADMIN, USER_LEVEL.GENERAL_ADMIN],
        },
        {
          icon: 'dollar-sign',
          text: 'Supplier',
          to: '/admin/supplier',
          name: 'admin-supplier',
          auth: [USER_LEVEL.SUPER_ADMIN, USER_LEVEL.GENERAL_ADMIN],
        },
        {
          icon: 'layer-group',
          text: 'Config',
          to: '/admin/config',
          name: 'admin-config',
          auth: [USER_LEVEL.SUPER_ADMIN, USER_LEVEL.GENERAL_ADMIN],
        },
        {
          icon: 'clipboard-list',
          text: 'Board',
          to: '/my-page/board',
          name: 'my-page-board',
          auth: [USER_LEVEL.SUPER_ADMIN, USER_LEVEL.GENERAL_ADMIN],
        },
        {
          icon: 'bell',
          text: 'Send Notification',
          to: '/admin/send-notification',
          name: 'admin-send-notification',
          auth: [USER_LEVEL.SUPER_ADMIN, USER_LEVEL.GENERAL_ADMIN],
        },
      ],
    },
  };
  public currentRoot = '';
  public currentSub = '';

  created() {
    this.onResize(this.$route);
  }

  mounted() {
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth;
    });
  }

  @Watch('$route')
  onResize(value: any) {
    const { menu } = value.meta as { menu: { root: string, sub: string } };
    const { root, sub } = menu;
    this.currentSub = sub
    this.currentRoot = root
  }

  get breakpoint() {
    return this.windowWidth > 1000;
  }

  private sideBarToggle(flag: boolean) {
    this.$emit('sideBarToggle', flag);
  }
}
