
import { Vue, Component } from 'vue-property-decorator';
import TheSidebar from '@/containers/the-sidebar.vue';
import TheHeader from '@/containers/the-header.vue';
import { Socket } from 'vue-socket.io-extended';
import { SOCKET_EVENTS } from '@/enum/enums';

@Component({
  components: { TheHeader, TheSidebar },
})
export default class test extends Vue {
  private visibleSidebar = true;
  sideBarToggle (flag: boolean) {
    this.visibleSidebar = flag;
  }
}
