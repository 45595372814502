
import { Component, Vue, Prop } from 'vue-property-decorator';
@Component({})
export default class SkeletonRows extends Vue {
  @Prop() rows!: number;
  @Prop() images?: boolean;
  @Prop() input?: boolean;
  @Prop() height?: number;

  private random(min: number, max: number) {
    return Math.random() * (max - min) + min;
  }
  get randomRows() {
    const array = new Array(this.rows).fill(0);
    return array.map(() => {
      return this.random(50, 100);
    });
  }
  get unit() {
    return this.height ? 'px' : '%'
  }
  get minHeight() {
    return this.height || 100;
  }
  get rowHeight() {
    if (this.height) return this.minHeight / this.rows * 0.5;
    return 15;
  }
}
