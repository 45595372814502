
import { Vue, Component, Prop } from 'vue-property-decorator';
import SocketComponent from '@/components/socket.vue';
import { SocketIO } from '@/plugins/socket';
import * as SocketIOClient from 'socket.io-client';
import { Socket } from 'vue-socket.io-extended';
import { SOCKET_EVENTS } from '@/enum/enums';
@Component({
  components: {
    SocketComponent
  }
})
export default class TheHeader extends Vue{
  private socket?: SocketIOClient.Socket;
  private signOutFlag = false;
  @Prop() visibleSidebar!: boolean;
  constructor() {
    super();
    this.socket = SocketIO.getInstance().connect();
  }

  async signOut(): Promise<boolean> {
    if (!this.signOutFlag) return this.signOutFlag = true;
    await this.$store.dispatch('signOut');
    await this.$router.replace('/');
    return this.signOutFlag = false;
  }

  signOutCancel(): void {
    this.signOutFlag = false;
  }

  get connected(): boolean {
    return this.$store.getters['sockets/connected'];
  }
  get userId(): string {
    return this.$store.getters.userID
  }
  get profileImage(): string | null {
    return this.$store.getters.profileImage;
  }

  @Socket(SOCKET_EVENTS.COLLECTION_MADE)
  collectionMade({ surveyId }: { surveyId: string }): void {
    console.log(surveyId);
  }
}
