import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';
import store from '@/store';
import { InvalidMessages } from '@/interface/interface';
import { USER_LEVEL } from '@/enum/enums';

@Component
export class GlobalMixin extends Vue {
  public loading = false;
  public $store = store;

  get processing(): boolean {
    return this.$store.getters['global/processing'];
  }

  proc(flag: boolean) {
    this.$store.commit('global/processing', flag);
  }

  successMessage(message: string): void {
    this.$notify({
      type: 'success',
      group: 'message',
      title: 'Success',
      text: message,
    });
    // this.$bvToast.toast(message, {
    //   title: 'Success',
    //   variant: 'success',
    //   bodyClass: 'white-space-pre'
    // })
  }
  errorMessage(message: string, options? : { duration?: number, speed?: number }): void {
    this.$notify({
      type: 'error',
      group: 'message',
      title: 'error',
      text: message,
      ...(options || {})
    });
  }
  validationMessages(messages?: string | string[] | InvalidMessages[], success?: boolean): string[] {
    const returnMessage: string[] = [];
    if (!Array.isArray(messages)) {
      const flag =  success ? this.successMessage(messages as string) : this.errorMessage(messages as string);
    } else if (messages) {
      messages.forEach((message: string | InvalidMessages) => {
        if (typeof message === 'string') {
          const flag =  success ? this.successMessage(message) : this.errorMessage(message);
          returnMessage.push(message);
        } else {
          const { field, message: msg } = message;
          for (const m of msg) {
            const flag =  success ? this.successMessage(m) : this.errorMessage(m);
            returnMessage.push(m);
          }
        }
      });
    }
    return returnMessage;
  }
  get getUserGroup(): string {
    return this.$store.getters.userGroup
  }
  get getUserName(): string {
    return this.$store.getters.userName
  }
  get getUserLevel(): USER_LEVEL {
    return this.$store.getters.userLevel
  }
  get getUserId(): string {
    return this.$store.getters.userID
  }
  get surveyId(): string {
    return this.$route.params.surveyId
  }
  get getLocale(): string {
    return this.$store.getters.locale
  }
  get projectStartDate(): string {
    return this.$store.getters.projectStartDate
  }
  set projectStartDate(date) {
    this.$store.commit('setProjectStartDate', date);
  }
  get projectEndDate(): string {
    return this.$store.getters.projectEndDate
  }
  set projectEndDate(date) {
    this.$store.commit('setProjectEndDate', date);
  }
  get isInternalUser() {
    const { PMI_USER, GENERAL_ADMIN, SUPER_ADMIN } = USER_LEVEL
    return [PMI_USER, GENERAL_ADMIN, SUPER_ADMIN].includes(this.getUserLevel);
  }
  get isExternalUser() {
    const { USER } = USER_LEVEL;
    return [USER].includes(this.getUserLevel);
  }
}
