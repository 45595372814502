import {Module} from 'vuex';

interface Registration {
  step: number,
  surveyId: string | null,
  btnDisabled: boolean
}

const registration: Module<Registration, any> = {
  namespaced: true,
  state: {
    step: 1,
    surveyId: null,
    btnDisabled: false,
  },
  getters: {
    step: (state): number => state.step,
    surveyId: (state): string | null => state.surveyId,
    btnDisabled: (state): boolean | null => state.btnDisabled,
  },
  mutations: {
    setStep(state, step: number) {
      state.step = step;
    },
    addStep(state, step: number) {
      state.step += step;
    },
    setSurveyId(state, surveyId: string) {
      state.surveyId = surveyId
    },
    setBtnDisabled(state, value: boolean) {
      state.btnDisabled = value
    }
  },
}


export default registration
