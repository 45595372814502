import { Locales } from "./locales";
import Store from '@/store'
import ko from './json/ko-KR.json'
import en from "./json/en-US.json";

export const messages = {
  [Locales.KO]: ko,
  [Locales.EN]: en,
};

export const validationKeys: { [key: string]: string } = {
  'required': 'REQUIRED',
  'min': 'MIN',
}


export const defaultLocale = Store.getters.locale;
