
import { Vue, Component, Prop, PropSync } from 'vue-property-decorator';
import { PROJECT_STATUS, INVOICE_STATUS, USER_LEVEL } from '@/enum/enums';

interface Response {
  status: PROJECT_STATUS
}

@Component({})
export default class ProjectStatus extends Vue {
  @Prop() _surveyId!: string;
  @Prop() my!: boolean;
  @Prop({ default: false }) isNav!: boolean;
  @Prop() invoiceStatus!: INVOICE_STATUS;
  @PropSync('status') statusSync!: PROJECT_STATUS;
  private statusChangeConfirmModalShow = false;
  private tmpChanged = '';

  variant = {
    CREATE: 'secondary',
    ON: 'primary',
    END: 'success',
    PAUSE: 'danger',
    CANCEL: 'outline-secondary',
  };
  dropdownItems = [
      PROJECT_STATUS.ON,
      PROJECT_STATUS.PAUSE,
      PROJECT_STATUS.END,
      PROJECT_STATUS.CANCEL,
  ];

  changeConfirm(current: string, changed: string) {
    if (current === changed) return;
    if (changed === PROJECT_STATUS.END) {
      this.statusChangeConfirmModalShow = true;
      this.tmpChanged = changed;
      return;
    }
    this.change(changed);
  }

  async change(changed: string) {
    this.proc(true);
    try {
      const {
        result,
        data,
        message,
      } = await this.axios.patch(`/project/status-change/${ this._surveyId }`, { status: changed });
      if (!result) throw message;

      const { status } = data as Response;
      this.statusSync = status;
      this.tmpChanged = '';

      if (this.isNav) {
        this.$store.commit('setProjectStatus', status);
      }
      this.$emit('changeStatus', status);
    } catch (e) {
      this.validationMessages(e);
    }
    this.proc(false);
  }

  get PROJECT_STATUS() {
    return PROJECT_STATUS
  }

  get INVOICE_STATUS() {
    return INVOICE_STATUS;
  }
}
