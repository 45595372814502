
import Vue from 'vue'
import Component from 'vue-class-component'
import { LOCALES } from "@/i18n/locales";
import { defaultLocale } from "@/i18n";

@Component
export default class LocaleChanger extends Vue {
  LOCALES = LOCALES
  defaultLocale = defaultLocale

  /*localeChange(value: string) {
    this.$i18n.locale = value;
    this.$store.dispatch('localeChange', this.$i18n.locale);
  }*/

  localeChange() {
    this.$store.dispatch('localeChange', this.$i18n.locale);
  }

  get path() {
    return this.$route.path;
  }

  get show() {
    return [this.path.startsWith('/error')].some(flag => flag);
  }

}
