import { Commit, Module } from 'vuex';
import { State } from '@/store';

interface GlobalInterface {
  processing: boolean;
}

const globalModule: Module<GlobalInterface, State> = {
  namespaced: true,
  state: {
    processing: false,
  },
  mutations: {
    processing(state, flag) {
      state.processing = flag;
    }
  },
  getters: {
    processing: state => {
      return state.processing;
    }
  }
};

export default globalModule;
