export enum SURVEY_STATUS {
  ALL = 'ALL',
  NONE = 'NONE',
  TARGETING = 'TARGETING',
  JOIN = 'JOIN',
  COMPLETE = 'COMPLETE',
  TERMINATE = 'TERMINATE',
  QUOTA_FULL = 'QUOTA_FULL',
  BAD_ANSWER = 'BAD_ANSWER',
  TERMINATE_WITH_NO_POINT = 'TERMINATE_WITH_NO_POINT',
  DUPLICATE = 'DUPLICATE',
}

export enum PROJECT_STATUS {
  TEMP = 'TEMP',
  CREATED = 'CREATED',
  ON = 'ON',
  PAUSE = 'PAUSE',
  END = 'END',
  CANCEL = 'CANCEL',
}


export enum USER_LEVEL {
  USER = 'user',
  PMI_USER = 'pmi-user',
  GENERAL_ADMIN = 'general-admin',
  SUPER_ADMIN = 'super-admin'
}

export enum NOTICE_SEARCH_TYPE {
  TYPE = 'type',
  TITLE = 'title',
  CONTENT = 'content',
  USER = 'user',
}

export enum NOTICE_TYPE {
  ANNOUNCEMENT = 'ANNOUNCEMENT',
  PANEL_INQUIRY = 'PANEL_INQUIRY',
  SPECIFIC = 'SPECIFIC',
  ANSWER = 'ANSWER',
}

export enum SOCKET_EVENTS {
  CONNECTED = 'connected',
  SURVEY_ROUTE_IN = 'surveyRouteIn',
  SURVEY_ROUTE_OUT = 'surveyRouteOut',
  SEND_MESSAGE = 'sendMessage',
  REC_MESSAGE = 'recMessage',
  JOIN_BOARD = 'joinBoard',
  LEAVE_BOARD = 'leaveBoard',
  NEW_COMMENT = 'newComment',
  COLLECTION_MADE = 'collectionMade',
  COLLECTION_MADE_PROGRESS = 'collectionMadeProgress',
}

export enum BUYER {
  ALL = 'ALL',
  CINT = 'CINT',
  GMO = 'GMO',
  DALIA = 'DALIA',
  LUCID = 'LUCID',
  INNOVATE_MR = 'INNOVATE_MR',
  SAMPICK = 'SAMPICK',
  DYNATA = 'DYNATA',
  BORDERLESS = 'BORDERLESS',
  IPSOS = 'IPSOS',
}

export enum PANEL {
  LIME = 'LIME',
  GS = 'GS',
  WIZ = 'WIZ',
}

export enum INVOICE_STATUS {
  YET = 'YET',
  ISSUABLE = 'ISSUABLE',
  DONE = 'DONE',
}

export enum LIST_UPLOAD_STATUS {
  NO_UPLOAD = 'NO_UPLOAD',
  UPLOADED = 'UPLOADED',
}

export enum PROJECT_SORT {
  DESC = 'DESC',
  ASC = 'ASC',
}
