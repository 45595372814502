import VueSocketIOExt from "vue-socket.io-extended";
import io from "socket.io-client";
import store from "@/store/index";
import Vue from "vue";
import Socket = SocketIOClient.Socket;

const IS_PROD = process.env.NODE_ENV === "production";
export class SocketIO {
  private static instance: SocketIO;
  private url = IS_PROD ? `/` : `wss://hub.pmidata.co.kr`;
  private client?: Socket;
  private readonly token: string | null = null;

  constructor() {
    const { getters } = store;
    const { accessToken: token } = getters;
    this.token = token || null;
  }

  public static getInstance() {
    return this.instance || (this.instance = new this());
  }
  get socket() {
    return this.client;
  }

  connect() {
    console.log(this.url);
    if (this.token && this.client === undefined) {
      this.client = io(this.url, this.options);
      console.log(this.client);
      Vue.use(VueSocketIOExt, this.client, { store });
      this.client.io.on("open", this.open);
      this.client.io.on("survey_route_in", () => {
        console.log("survey_route_in");
      });
    }
    return this.client;
  }
  open() {
    console.warn("open");
  }

  get options() {
    const { getters } = store;
    const { accessToken: token } = getters;
    return {
      query: {
        token,
      },
      reconnection: true,
      reconnectionDelay: 1000 * 5,
      reconnectionDelayMax: 5,
      forceNew: true,
      transports: ["websocket"],
      // withCredentials: true,
    };
  }
}
