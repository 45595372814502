
import { Vue, Component } from 'vue-property-decorator';

@Component({})
export default class App extends Vue {
  get fullPath(): string {
    return this.$route.fullPath;
  }

}
