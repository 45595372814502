
import Vue from 'vue';
import Component from 'vue-class-component';
import moment from 'moment';
import ProjectStatus from '@/pages/project/components/project-status.vue';
import ListFilter from '@/pages/project/components/list-filter.vue';
import { Socket } from 'vue-socket.io-extended';
import { INVOICE_STATUS, PROJECT_STATUS, SOCKET_EVENTS, SURVEY_STATUS, USER_LEVEL } from '@/enum/enums';
import { Pagination } from '@/interface/interface';

interface Docs {
  bookmark: boolean,
  endDate: string,
  groupId: string,
  manager: string,
  my: boolean,
  show: { [key in SURVEY_STATUS]: boolean },
  startDate: string,
  status: PROJECT_STATUS,
  summary: { [key in SURVEY_STATUS]: number },
  surveyId: string,
  surveyNo: number,
  title: string,
  invoiceStatus: INVOICE_STATUS,
}

@Component({
  components: {
    ListFilter,
    ProjectStatus,
  },
})
export default class ProjectList extends Vue {
  page = 1;
  totalPages = 0;
  totalDocs = 0;
  limit = 10;
  error = null;
  filterType = '';
  filterValue = '';
  sortOrder = '';
  list: Docs[] = [];

  async created() {
    this.loading = true;
    await this.load();
    this.loading = false;
  }

  async changeBookmark(surveyId: string) {
    this.loading = true;
    try {
      const { result } = await this.axios.patch(`project/bookmark/${ surveyId }`, { bookmark: true });
      if (result) await this.load();
    } catch (e) {
      this.validationMessages(e);
    }
    this.loading = false;
  }

  async load() {
    try {
      this.loading = true;
      const {
        page,
        filterType,
        filterValue,
        sortOrder = 'DESC',
        startDate: queryStartDate,
        endDate: queryEndDate,
      } = this.$route.query as { [key: string]: string };

      this.projectEndDate = '';

      this.sortOrder = sortOrder;
      if (page) this.page = parseInt(page, 10);
      if (queryStartDate) this.projectStartDate = queryStartDate;
      if (queryEndDate) this.projectEndDate = queryEndDate;

      const { data: { startDate, endDate } } = await this.axios.get(`project/default-date`);
      if (!this.projectStartDate) this.projectStartDate = startDate;
      if (!this.projectEndDate) this.projectEndDate = endDate;

      const params: { [key: string]: string | number } = {
        sortOrder,
        startDate: this.projectStartDate,
        endDate: this.projectEndDate,
        page: this.page,
        limit: this.limit,
      };

      if (filterType && filterValue) {
        this.filterType = filterType;
        this.filterValue = filterValue;
        params[filterType] = filterValue;
      }

      const { result, data, message } = await this.axios.get<Pagination<Docs>>('project', { params });
      if (!result) throw message;
      else {
        const { docs, page, totalPages, totalDocs } = data;
        this.page = page;
        this.list = docs;
        this.totalPages = totalPages;
        this.totalDocs = totalDocs;
      }
    } catch (e) {
      this.validationMessages(e);
    } finally {
      this.loading = false;
    }
  }

  makeFilter(page = '1') {
    const isSearchFilter = this.filterType && this.filterValue;
    const searchFilter = isSearchFilter ? `&filterType=${ this.filterType }&filterValue=${ this.filterValue }` : '';
    const dateFilter = `&startDate=${ this.projectStartDate }&endDate=${ this.projectEndDate }`;
    const orderBy = `&sortOrder=${ this.sortOrder }`;

    return `?page=${ page }${ orderBy }${ dateFilter }${ searchFilter }`;
  }

  filterSubmit(data: { filterType: string; filterValue: string; sortOrder: string; startDate: string; endDate: string; }) {
    const { filterType, filterValue, sortOrder, startDate, endDate } = data;
    if (filterType) this.filterType = filterType;
    if (filterValue) this.filterValue = filterValue;
    if (sortOrder) this.sortOrder = sortOrder;
    if (startDate) this.projectStartDate = startDate;
    if (endDate) this.projectEndDate = endDate;
    const query = this.makeFilter();
    this.$router.push(query).catch(() => ({}));
  }

  linkGen(page: string) {
    return this.makeFilter(page);
  }

  dateFormat(date: string) {
    return moment(date).format('YYYY-MM-DD');
  }

  async projectCopy(surveyId: string) {
    try {
      const { result, message } = await this.axios.put(`/project/${ surveyId }`);
      if (result) await this.load();
    } catch (e) {
      console.log(e);
    }
  }

  toSpecific(status: PROJECT_STATUS, surveyId: string) {
    return status === PROJECT_STATUS.TEMP ? `/project/registration/${ surveyId }` : `/project/created/home/${ surveyId }?${ this.makeFilter(this.page.toString()) }`;
  }

  @Socket(SOCKET_EVENTS.SURVEY_ROUTE_IN)
  routeIn(data: any) {
    console.log(data);
  }

  @Socket(SOCKET_EVENTS.SURVEY_ROUTE_OUT)
  async routeOut(data: { surveyId: string, status: SURVEY_STATUS }) {
    const { surveyId, status } = data;
    console.log(data);
    const index = this.list.findIndex((l) => l.surveyId === surveyId);
    if (index > -1) {
      const { summary, show } = this.list[index];
      switch (status) {
        case SURVEY_STATUS.COMPLETE:
          summary.COMPLETE += 1;
          break;
        case SURVEY_STATUS.TERMINATE:
          summary.TERMINATE += 1;
          break;
        case SURVEY_STATUS.QUOTA_FULL:
          summary.QUOTA_FULL += 1;
          break;
      }
      show[status] = false;
      await this.$nextTick();
      show[status] = true;
    }
  }

  statusEditable(my: boolean, status: PROJECT_STATUS) {
    switch (this.getUserLevel) {
      case USER_LEVEL.USER: {
        const { TEMP, CREATED, CANCEL } = PROJECT_STATUS;
        return my && ![TEMP, CREATED, CANCEL].includes(status);
      }
      case USER_LEVEL.PMI_USER: {
        return my;
      }
      default: {
        return true;
      }
    }
  }

  get PROJECT_STATUS() {
    return PROJECT_STATUS;
  }
  get INVOICE_STATUS() {
    return INVOICE_STATUS;
  }
}

