var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('router-link',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:([_vm.id]),expression:"[id]"}],class:[
          'root-menu',
          _vm.menu.name.includes(_vm.currentRoot) ? 'link-active' : '',
          ],attrs:{"to":_vm.menu.to}},[_c('div',[_c('font-awesome-icon',{staticClass:"mr-2",attrs:{"icon":_vm.menu.icon}}),_vm._v(" "+_vm._s(_vm.menu.text)+" ")],1)]),_c('b-collapse',{attrs:{"id":_vm.id,"accordion":"accordion","visible":_vm.menu.name.includes(_vm.currentRoot)}},[(_vm.menu.sub && _vm.menu.sub.length)?_c('b-card',{staticClass:"mb-1 mt-3",attrs:{"no-body":""}},[_c('b-card-body',{staticClass:"p-1 text-dark"},[_vm._l((_vm.menu.sub),function(l,idx){return [(l.auth.includes(_vm.getUserLevel))?_c('router-link',{key:`${ l.text }-${ idx }`,class:[
                'text-decoration-none',
                _vm.currentSub === l.name ? 'font-weight-bold' : '',
                ],attrs:{"to":l.to}},[_c('div',{staticClass:"px-3 py-2 rounded sub-menu"},[_c('small',[_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":l.icon}})],1),_vm._v(" "+_vm._s(l.text)+" ")])]):_vm._e()]})],2)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }