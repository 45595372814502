import { Commit, Module, Store } from 'vuex';
import { SocketMessage } from '@/interface/interface';
import { SOCKET_EVENTS } from '@/enum/enums';
import { Vue, Component } from 'vue-property-decorator';
import { State } from '../index';
interface socketInterface {
  socketId: string;
  connected: boolean;
}

const sockets: Module<socketInterface, State> = {
  namespaced: true,
  mutations: {
    'SOCKET_CONNECTED'(state, socketId) {
      state.socketId = socketId;
      state.connected = true;
    }
  },
  getters: {
    connected(state) {
      return state.connected;
    },
    socketId(state) {
      return state.socketId;
    }
  },
  actions: {
    sendMessage(context, { message, vm }: { message: SocketMessage, vm: Vue }) {
      // vm.$socket.client.emit(SOCKET_EVENTS.SEND_MESSAGE, message, (data: any) => {
      // });
    },
    joinBoard(context, { _id, vm }: { _id: string, vm: Vue }) {
      // vm.$socket.client.emit(SOCKET_EVENTS.JOIN_BOARD, { _id }, () => {
      // });
    },
    leaveBoard(context, { _id, vm }: { _id: string, vm: Vue }) {
      // vm.$socket.client.emit(SOCKET_EVENTS.LEAVE_BOARD, { _id }, () => {
      // });
    },
    newComment(context, { _id, vm }: { _id: string, vm: Vue }) {
      // vm.$socket.client.emit(SOCKET_EVENTS.NEW_COMMENT, { _id }, () => {
      //
      // });
    },
    'socket_newComment'(context, { _id }: { _id: string }) {
      console.log(_id);
    },
    recMessage(context, message: SocketMessage) {
      console.log(message);
    },
    socketOpen(context, { vm }) {
      console.log(vm);
      vm.$socket.client.emit(SOCKET_EVENTS.CONNECTED, { result: 'OK' });
    },
    'socket_connected' ({ commit }: { commit: Commit }, { user, socketId }: { user: { userId: string, userName: string }, socketId: string }) {
      console.log(socketId);
    },
    'socket_surveyRouteIn' ({ commit }: { commit: Commit }, data: any) {
      console.log(data);
    },
    'socket_surveyRouteOut' ({ commit }: { commit: Commit }, data: any) {
      console.log(data);
    },
    'socket_recMessage' ({ commit }: { commit: Commit }, message: SocketMessage) {
      console.log(message);
    },
    //  COLLECTION_MADE = 'collectionMade',
    //   COLLECTION_MADE_PROGRESS = 'collectionMadeProgress',
    [`socket_${SOCKET_EVENTS.COLLECTION_MADE}`] ({ commit }: { commit: Commit }, data: any) {
      console.log(data);
    },
    [`socket_${SOCKET_EVENTS.COLLECTION_MADE_PROGRESS}`] ({ commit }: { commit: Commit }, data: any) {
      console.log(data);
    }
  }
};

export default sockets;
