import axios, { AxiosResponse } from "axios";
import store from "@/store";
import router from "@/router/index";
import App from "@/main";

const baseURL = process.env.NODE_ENV === "development" ? "/api" : "/api";

const instance = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
  },
  timeout: 1e3 * 60,
});

instance.interceptors.request.use((config) => {
  if (store) {
    const { accessToken, locale } = store.getters;
    config.headers["Accept-Language"] = locale;
    if (accessToken) config.headers["Authorization"] = `Bearer ${accessToken}`;
  }
  return config;
});

instance.interceptors.response.use(
  (response) => {
    const { validationMessages } = App;
    const { data, headers, status } = response;
    if (response.data instanceof Blob) {
      return {
        data,
        headers,
        result: true,
      } as AxiosResponse;
    }
    const { message, response: dataResponse, _doc } = data;
    // if (message) validationMessages(message, true);
    const successObj = {
      message: Array.isArray(message) ? message : [message],
      data: _doc ? _doc : dataResponse,
      result: true,
    } as AxiosResponse;

    return successObj;
  },
  (error) => {
    const { response } = error;
    const { data, status } = response;
    const { validationMessages } = App;
    if (status === 406) {
      // return router.push({ path: '/error/406' });
      return router.replace({ path: "/error/406" });
    }

    if (data) {
      const { statusCode, message } = data;
      const errorObj = {
        // toast 필요한 에러코드 기본 값 999
        statusCode: 999,
        message: Array.isArray(message) ? message : [message],
        result: false,
      };
      // toast 안 띄워주는 에러코드
      const tmpArr = [422, 409];
      // if (message) validationMessages(message);
      if (tmpArr.includes(statusCode)) errorObj.statusCode = statusCode;

      return errorObj;
    }
  }
);

export default instance;
